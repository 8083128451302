import Accordion from "./modules/accordion";
import Tabs from "./modules/tabs";
import MobileMenu from "./modules/mobile-menu";
import Animate from "./modules/animate";
import GalleryNav from "./modules/gallery-nav";
import TargetContent from "./modules/target-content";
import SimpleParallax from "./modules/simple-parallax";
import VideoAutoplay from "./modules/video-autoplay";
import Popup from "./modules/popup";

//Accordion
Accordion();

//Tabs
Tabs();

//Mobile menu
MobileMenu();

//Animate
Animate();

//GalleryNav
GalleryNav();

//TargetContent
TargetContent();

// SimpleParallax
SimpleParallax();

// VideoAutoplay
VideoAutoplay();

// Popups;
Popup();
